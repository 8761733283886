import mtech from '../images/mtech.png';
import appleLogo from '../images/Apple_logo_black.png';
import './LandingPage.css';

function LandingPage() {
    return (
        <div className="Landing-page-bg">
            <header className="App-header">
                <img src={mtech} className="App-logo" alt="logo" />
                <a href='https://apps.apple.com/us/developer/misquamicut-technologies-llc/id1094945438'>
                    <button className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow mt-11 inline-flex items-center">
                        <img src={appleLogo} className="object-contain h-8 w-8 mr-4" alt="apple" />
                        <span>iOS Apps</span>
                    </button>
                </a>
                <a href='mailto:info@misquamicuttech.com'>
                    <button className="bg-transparent hover:bg-white text-white font-semibold hover:text-black py-2 px-4 border-2 border-white mt-4 hover:border-transparent rounded">
                        Contact
                    </button>
                </a>
            </header>
        </div>
    )
}

export default LandingPage;