// import mtech from './mtech.png';
// import './App.css';
import LandingPage from './LandingPage/LandingPage';

function App() {
  return (
    <LandingPage/>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={mtech} className="App-logo" alt="logo" />
    //   </header>
    // </div>
  );
}

export default App;
